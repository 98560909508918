.helpingfarmer-section {
    background: #fff;
  }
    .mtb-50{margin-top: 50px;margin-bottom: 6vw;}
    /* .logo-align{width:121px;} */
    /*Inner Banner*/
    .generalInnerBanner{width:100%;display: flex;height:374px;align-items: center;}
    .natural-farming-banner h1{float:left;font-size:32px;color: #fff;;position: relative;}
    .natural-farming-banner h1:before{content:"";width: 48px;;height:3px;background-color: #deb887;position: absolute;}
    .natural-farming-banner{background-size: cover;display: flex;flex-direction: column;justify-content: center;}
    .top-inner-title{ clear: both; font-size: 20px; color: #fff; margin-top:5px; display: block;
      float: left;}
      .lifecycle-image{text-align: center;}
   .approach-sec h2{margin-bottom: 50px;}