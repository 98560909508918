/**Media Query**/

@media screen and (min-width:991px) {
  .navbar-expand-lg .navbar-collapse {
    float: right;
  }

  .dropdown-toggle::after {
    background-image: url(../img/arrow-down.png);
    width: 12px;
    height: 8px;
    border: 0;
    position: relative;
    left: 3px;
    vertical-align: middle;
  }

  .dropdown-toggle:hover::after {
    background-image: url(../img/arrow-down-hover.png);
  }


  .main-menu a {
    margin-right: 1vw !important;
    padding: 0.943vw 0.871vw !important;
    line-height: 1.161vw;
    /* font-size: 1.016vw; */
    font-size:0.86vw;
  }
  .banner-card{
    margin-top: 18vw;
  }

}


@media screen and (min-width:768px) {
  .lake-program-inner {
    min-height: 11.030vw;
  }

  .lake-program-section h3 {
    font-size:1.34vw;
    margin-bottom: 0.726vw;
  }

  .lake-program-section .lake-program-date {
    font-size: 0.871vw;
    line-height: 1.597vw;
  }

  .lake-program-section p {
    font-size: 1.23vw;
    line-height: 1.669vw;
  }

  .lake-program-section .desc-content a {
    font-size: 1.23vw;
    line-height: 1.597vw;
  }

  .lake-program-section .title-content {
    padding-left: 1.306vw;
  }

  .top-title {
    font-size: 2.322vw;
    line-height: 2.322vw;
    margin-bottom: 2.395vw;
  }

  .our-achive-inner-desc h4 {
    font-size: 2.032vw;
    line-height: 3.048vw;
  }

  .our-achive-inner-desc p {
    font-size: 1.161vw;
    line-height: 1.887vw;
  }

  .our-achive-inner-desc {
    padding: 1.0vw;
    /* min-height: 10.046vw; */
  }

  .ach-desc {
    padding-right: 0;
  }

  .desc-content {
    padding-left: 2.177vw;
  }


  .welcom-mo-mon .title-text {
    font-size: 2.322vw;
    line-height: 3.483vw;
    margin: 0 0 1.451vw;
  }

  .our-mo-mon-desc {
    padding-top: 1.089vw;
    padding-right: 2.903vw;
  }

  .our-mo-mon-desc p {
    font-size: 1.161vw;
    line-height: 2.322vw;
    margin-bottom: 1.089vw;
  }

  .our-wel-imges::after {
    height: 33.019vw;
    top: 5.007vw;
    right: -4%;
  }
  .banner-card{
    margin-top: 26vw;
  }
  
  
}

@media screen and (min-width:640px) {
   /* caraoussal style */
.w-100 {
  height: 82vh;
}
 .carousel-inner{
  background:#475443 url(../img/banner.png) no-repeat;
  background-position: top center;
  background-size: cover;
 } 
 .carousel-item{ background-color: transparent;}
 .carousel-item:nth-child(3){
  background:#475443 url(../img/slider3-home-page-banner.jpg) no-repeat;
  background-position: top center;
  background-size: cover;
 }
 .carousel-item:nth-child(3) .banner-img .inner-banner{
  visibility: hidden;
 }
  .Home-banners {
    /* margin-top: -37.778vw; */
    /* position: absolute;
    bottom: auto;
    top: 4.72vw;
    left: 3.13vw; */
  }

  /*subscription*/

  .lets-us-work::before{
    height: 3.68vw;
    top: -3.4vw;
  }
  .lets-us-work::after{
    width: calc(100% - 187px);
  }
  .lets-us-work .row{
    min-height: 14.03vw; 
  }
  .live_project-info h3{
    font-size: 2.22vw;
    line-height: 2.22vw;
  }
  .live_project-info p{
    font-size: 1.11vw;
    line-height: 2.22vw;
  }
  .subscription-inner .text-input{
    line-height: 2.22vw;
    width: 28.82vw;
    height: 3.9vw;
    padding: 0.42vw 1.39vw 0.42vw 0.97vw;
    font-size: 0.972vw;
  }
  .btn-newsletter{
    width: 8.13vw;
    height: 3.9vw;
    font-size: 0.97vw;
    line-height: 1.11vw;
    margin-left: -2.04vw;
  }


  .our-achive-inner-desc p{
    font-size:1.111vw;
    line-height:1.667vw;
    
  }
  .our-supporter{
    padding:3.47vw 0vw;
  }
  .supporter-top-title h3{
    font-size: 2.22vw;
    line-height: 3.33vw;
  }
  .supporter-itme{
    margin-top: 3.68vw;
   }
   .supporter-list-inner{
    padding: 3.47vw 2.08vw;
   }
   .supporter-desc{
    margin-top: 3.33vw;
   }
   .supporter-desc p{
    font-size: 1.11vw;
    line-height: 2.22vw;
   }

   .work-section h4 {
    margin-top: 1.74vw;
     font-size: 1.11vw;
    line-height: 1.11vw;
   }
   .work-section p {
    font-size: 0.97vw;
    line-height: 1.67vw;
    max-width: 100%;
    width: 100%;
   }

   .restoration-imges::after {
    bottom: 0;
    left: -7%;
    z-index: -1;
    height: 29.019vw;
    top: 16.007vw;
  }
  .restoration-imges{
    padding-bottom: 20px;
  }

  .banner-text h3 {
    font-size: 2.22vw;
    line-height: 3.33vw;
  }
  .banner-text p {
    font-size: 1.11vw;
    line-height: 2.22vw;
    margin: 0.56vw 0;
  }
  .carousel-indicators{
    right: 0;
    left: 4.14vw;
    margin: 0 0 2.08vw;
    justify-content: left;
  }
  .carousel-item img.show-for-mobile-only{ display: none!important;}
  .inner-banner{
    margin-top: 5.069vw;
  }
  .banner-text h3{ margin: 0;}
  .banner-card {
    margin-bottom: 5vw;
    margin-top: 0;
  }
  .cap1{ align-items: end;}
  .our-achive-inner-desc {
    max-height: 11.528vw;
  }
  .image-ph{
    margin: 8px 0;
  }

  .about_inner{
    margin: 3.472vw 0 1.39vw;
  }
  .about-inner-desc h3{
    font-size: 2.22vw;
    line-height: 3.47vw;
    margin: 0 0 1.39vw;
  }
  .about-inner-desc h4{
    font-size: 2.22vw;
    line-height: 3.47vw;
  }
  .about-inner-desc p{
    font-size: 1.11vw;
    line-height: 2.22vw;
    margin-bottom:1.39vw;
  }

  .about-inner-desc .more-policies{
    font-size: 0.97vw;
    line-height: 1.46vw;
  }
  .about-quotes{
    padding-left: 5vw;
  }
  .about-quotes span img{
    margin-bottom: 1.04vw;
  }
  .about-quotes-desc{ 
  max-width: 40.21vw;
  margin-bottom: 6.25vw;
  }
  .about-quotes-desc p{
    font-size: 2.78vw;
    line-height: 4.17vw;
  }
  .contact-form-title{
    font-size: 2.222vw;
    line-height: 3.472vw; 
  }
  .our-map-data{
    margin-top:3.54vw;
    margin-bottom: 3.25vw;
  }

  .our-values-full{
    height:24.38vw;
    padding: 3.96vw 0 4.58vw;
    margin: 2.986vw 0 0;
  }
  .our-values-title{ 
    margin: 0 0 3.47vw;
  }
  .our-values-title h3{
    font-size: 2.22vw;
    line-height: 3.33vw;
  }
  .our-values-inner-items h5{
    font-size: 1.11vw;
    line-height: 2.22vw;
    margin: 0 0 0.35vw;
  }
  .our-values-inner-items p{
    font-size: 0.97vw;
    line-height: 1.46vw;
  }

  .out-line-section-part_inner{
    padding:3.9vw 0 0;
  }
  .out-line-widget-content{ padding-bottom: 4.9vw;}
  .out-line-widget-desc{ padding-bottom: 4.9vw;}
  .out-line-widget-title{
    margin-bottom: 1.04vw;
  }

  .out-line-widget-title h3{
    font-size: 2.22vw;
    line-height: 3.33vw; 
  }
  .out-line-widget-desc h4{
    margin: 0 0 1.04vw;
    font-size: 1.39vw;
    line-height: 2.08vw;
  }
  .out-line-widget-desc p{
    margin:  0 0 1.04vw;
    font-size: 1.11vw;
    line-height: 2.22vw;
  }
  .out-line-widget-img-part{
    margin-top: 2.78vw;
  }
  .out-line-widget-img-part::after {
    height: 34.65vw;
    top: 0vw;
    right: -12.08vw;
  }
  .out-line-widget-img-part.left-line::after {
    left: -12.292vw;
    right: 0;
  }

  .our-mission-part .out-line-widget-desc{
    padding-bottom:0;
    /* padding-bottom:2.08vw; */
  margin-top: 2.08vw;;
  }
  .our-mission-part .out-line-widget-desc h3{
    font-size: 2.22vw;
    line-height:3.333vw;
    margin-bottom: 1.39vw;    
  }
  .our-mission-part .out-line-section-part_inner {
    padding: 4.86vw 0;
  }
  .restoration-desc p {
    font-size: 1.11vw;
  line-height: 2.22vw;
  }
  .restor-works p{
    font-weight: 400;
  font-size: 1.11vw;
  line-height: 2.22vw;
  }

  .lg-container .lg-next,
  .lg-container .lg-prev {
    width: 1.389vw;
    height: 2.083vw;
    bottom: 4.306vw;
  }

  .lg-counter, .lg-counter-all,
.lg-counter .lg-counter-current{
  font-size: 1.111vw;
  line-height: 3.472vw;
}

.left-image-part-philosophy{margin: 5.069vw 0 0;}
.left-image-part-philosophy::after{
  height: 30.556vw;
  bottom: 1.736vw;
  left: -20.833vw;
}

.our-gallery-items .gallery-list-items{
  height: 17.778vw;
}

/*media end 640*/
}

@media (min-width: 1200px) {
  .carousel-indicators{
    left: calc(50% - 570px);
    /* bottom:73px; */
  }

  .banner-card {
    margin-bottom: 12vw;
    margin-top: 0;
  }
  .cap1 {
    align-items: center;
}
.banner-card {
  margin-bottom: 0;
}

  /* .banner-img:before {
  content: " ";
  width: 50%;
  border-top: 1px solid #ffe3b4;
  border-radius: 16px;
  position: absolute;
  height: 33.019vw;
  top: 2.007vw;
  right: -8.5%;
  z-index: -1;
  border-left: 1px solid #ffe3b4;
} */
  .banner-text p {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    /* font-size: 16px;
    line-height: 32px; */
    color: #F1EEEB;
    /* margin-top: 1rem;
    margin-bottom: 2rem; */
    width: 90%;
  }
  .w-100{height: 91vh;}
  .our-gallery-items .gallery-list-items{
    height: 256px;
  }
}

@media (min-width:1400px) {
  .carousel-indicators{
    left: calc(50% - 660px);
  }
  .carousel-indicators{
    margin: 0 0 1.08vw;
    /* bottom: 147px; */
  }
}

@media screen and (min-width:1500px) {
  .main-menu a {
    font-size: 14px;
    line-height: 25px;
    margin-right: 20px !important;
    /* padding-right: 40px !important; */
    padding: 13px 12px !important;
    line-height: 14px;
  }

  .navbar-nav .special-btn .sign-in-btn {
    margin-right: 20px
  }

  .navbar-nav .special-btn {
    margin-left: 0px;
  }

  .navbar-nav {
    padding: 21px 0px;
  }
  .special-btn  .navbar-nav { padding: 0;}

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 4px;
    transform: translate(0px, 67px);
    left: -83%;
  }
  .navbar-expand-lg .navbar-nav .support-sub-menu .dropdown-menu{
    left: 0;
  }

  .lake-program-inner {
    min-height: 154px;
  }

  .lake-program-section h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .lake-program-section .lake-program-date {
    font-size: 12px;
    line-height: 22px;

  }

  .lake-program-section p {
    font-size: 14px;
    line-height: 23px;
    text-align: left;
  }

  .lake-program-section .desc-content a {
    font-size: 14px;
    line-height: 22px;
  }

  .lake-program-section .title-content {
    padding-left: 18px;
  }

  .top-title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 33px;
  }

  .our-achive-inner-desc h4 {
    font-size: 28px;
    line-height: 42px;
  }

  .our-achive-inner-desc p {
    font-size: 16px;
    line-height: 26px;
  }

  .our-achive-inner-desc {
    padding: 25px;
    /* min-height: 166px; */
  }

  .desc-content {
    padding-left: 30px;
  }

  .welcom-mo-mon .title-text {
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 20px;
  }

  .our-mo-mon-desc {
    padding-top: 15px;
    padding-right: 40px;
  }

  .our-mo-mon-desc p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .our-wel-imges::after {
    height: 482px;
    top: 67px;
    right: -9%;
    z-index: -1;
  }
/*subscription*/

  .lets-us-work::before{
    height: 53px;
    top: -49px;
  }
  .lets-us-work::after{
    width: calc(100% - 421px);
  }
  .lets-us-work .row{
    min-height: 202px; 
  }
  .live_project-info h3{
    font-size: 32px;
    line-height: 32px;
  }
  .live_project-info p{
    font-size: 16px;
    line-height: 32px;
  }
  .subscription-inner .text-input{
    line-height: 32px;
    width: 415px;
    height: 44px;
    padding: 6px 20px 6px 14px;
    font-size: 14px;
  }
  .btn-newsletter{
    width: 117px;
    height: 44px;
    font-size: 14px;
    line-height: 16px;
    margin-left: -15px;
  }

  .our-supporter{
    padding:50px 0px;
  }
  .supporter-top-title h3{
    font-size: 32px;
  line-height: 48px;
  }
  .supporter-itme{
    margin-top: 53px;
   }

   .supporter-list-inner{
    padding: 50px 30px;
   }
   .supporter-desc{
    margin-top: 48px;
   }
   .supporter-desc p{
    font-size: 16px;
    line-height: 32px;
   }

   .work-section h4 {
    margin-top: 25px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
   }
   .work-section p {
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
    width: 100%;
   }


   .restoration-imges::after {
    height: 424px;
    bottom: 160px;
    left: -7%;
    z-index: -1;
    top: auto;
  }
  .organic-group .restoration-imges::after {
    bottom: 94px;
  }
  .Home-banners {
    /* margin-top: -42rem; */
    /* position: absolute;
    bottom: 0;
    left:calc(50% - 660px);
    top:auto */
  }
  .banner-text h3 {
    /* font-size: 32px; */
    font-size: 25px;
    line-height: 48px;
  }
  .banner-text p {
    font-size: 16px;
    line-height: 32px;
    margin: 8px 0;
  }

  .carousel-indicators{
    right: 0;
    left: calc(50% - 660px);
    margin: 0 0 30px;
    justify-content: left;
    /* bottom: 176px; */
  }

  .inner-banner{
    margin-top: 109px; 
  }
  .banner-card {
    /* margin-bottom: 260px; */
    margin-bottom: 0;
    margin-top: 0;
  }
  .our-achive-inner-desc {
    max-height: 166px;
  }


  .about_inner{
    margin:50px 0 20px;
  }
  .about-inner-desc h3{
    font-size: 32px;
    line-height: 50px;
    margin: 0 0 20px;
  }
  .about-inner-desc h4{
    font-size: 32px;
    line-height: 50px;
    
  }
  .about-inner-desc p{
    font-size: 16px;
    line-height: 32px;
    margin-bottom:20px;
  }

  .about-inner-desc .more-policies{
    font-size: 14px;
    line-height: 21px;
  }
  .about-quotes{
    padding-left: 72px;
  }
  .about-quotes span img{
    margin-bottom: 15px;
  }
  .about-quotes-desc{ 
    max-width: 579px;
  margin-bottom: 90px;
  }
  .about-quotes-desc p{
    font-size: 40px;
    line-height: 60px;
  }
  .contact-form-title{
    font-size: 32px;
  line-height: 50px; 
  }
  .our-map-data{
    margin-top:51px;
    margin-bottom: 90px;
  }
  .our-values-full{
    height:351px;
    padding: 57px 0 66px;
    margin: 43px 0 0;
  }
  .our-values-title{ 
    margin: 0 0 50px;
  }
  .our-values-title h3{
    font-size: 32px;
    line-height: 48px;
  }
  .our-values-inner-items h5{
    font-size: 16px;
    line-height: 32px;
    margin: 0 0 5px;
  }
  .our-values-inner-items p{
    font-size: 14px;
    line-height: 21px;
  }

  .out-line-section-part_inner{
    padding: 70px 0 0;
  }
  .out-line-widget-content{ padding-bottom: 70px;}
  .out-line-widget-desc{ padding-bottom: 70px;}
  .out-line-widget-title{
    margin-bottom: 15px;
  }

  .out-line-widget-title h3{
    font-size: 32px;
    line-height: 48px; 
  }
  .out-line-widget-desc h4{
    margin: 0 0 15px;
    font-size: 20px;
    line-height: 30px;
  }
  .out-line-widget-desc p{
    margin:  0 0 15px;
    font-size: 16px;
    line-height: 32px;
  }
  .out-line-widget-img-part{
    margin-top: 40px;
  }
  .out-line-widget-img-part::after {
    height: 499px;
    top: 0px;
    right: -174px;
  }
  .out-line-widget-img-part.left-line::after{
    left: -177px;
    right: 0;
  }
  
  .our-mission-part .out-line-section-part_inner{
    padding:70px 0;
  }
  .our-mission-part .out-line-widget-desc{
    /* padding-bottom:30px; */
    padding-bottom: 0;
    margin-top: 30px;
  }
  .our-mission-part .out-line-widget-desc h3{
    font-size: 32px;
    line-height:48px;
    margin-bottom: 20px;    
  }
  .restoration-desc p {
    font-size: 16px;
    line-height: 32px;
  }

  .lg-container .lg-next,
  .lg-container .lg-prev {
    width: 20px;
    height: 30px;
    bottom: 62px;
  }

  .lg-counter, .lg-counter-all,
.lg-counter .lg-counter-current{
  font-size: 16px;
  line-height: 50px;
}

.left-image-part-philosophy::after{
  height: 440px;
  bottom: 25px;
  left: -300px;
}

.left-image-part-philosophy{margin: 73px 0 0;}


/*media end 1500px*/


}



@media screen and (min-width:768px) and (max-width:896px){
  /* .w-100 {
    height: auto;
  } */
  /* .Home-banners{
      left: calc(50% - 372px);
   } */
  .carousel-indicators{
    left: calc(50% - 360px);
  }
  .restoration-imges::after{
    top: 23vw;
  }
  .organic-group .restoration-imges::after {
    top: 19vw;
  }
  .w-100 {
    height: 48vh;
}
.info-sec1 .col1 p{margin-top: 0 !important;}
.contactfrom{
  padding-bottom:1rem;
}
.contactfrom{
  padding-bottom: 1rem;
}
.our-map-title{ font-size: 27px;}
}
@media screen and (min-width:900px) and (max-width:1180px){
  .w-100 {
    height: auto;
  }
  .Home-banners{
    left: calc(50% - 479px);
 }
.carousel-indicators{
  left: calc(50% - 473px);
}

  .restoration-imges::after{
    top: 20vw;
}
.modal .modal-body img{
width: auto;
margin: 0px auto 0;
text-align: center;
display: block;
max-width: 100%;
}
}


@media screen and (max-width:768px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    transform: none;
    max-height: 300px;
    overflow-y: scroll;
  }

  .maga-main-menu {
    margin: 2.533vw 0 0;
  }

  .maga-main-menu .text-left {
    padding: 0;
    margin-bottom: 2vw;
    width: 100%;
  }

  .maga-main-menu {
    padding: 0;
  }

  .maga-main-menu .row {
    margin: 0;
  }

  .bg-sal-col {
    background-color: #fff !important;
  }

  .mail-us {
    font-size: 14px;
    line-height: 16px;
  }

  .inner-page-btm-footer {
    margin-top: 20px;
  }
  .lg-outer img.lg-object{
    vertical-align: middle;
  }
  .modal .modal-body img{
    width: auto;
    margin: 0px auto 0;
    text-align: center;
    display: block
  }
  .gallery-imgs-sec .gallery-content{
    font-size: 27px;
    line-height: 50px;
  }
}

@media screen and (max-width:767px) {
  /* .carousel-item::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgb(71 84 67) 0%, rgba(0, 0, 0, 0.11) 100%, rgb(71 84 67) 100%);
    } */

  .hide-for-small-only {
    display: none;
  }

  .shwo-for-small-only {
    display: block;
  }

  .navbar-collapse .main-menu {
    padding-top: 1vw;
  }
  .scrolled .navbar-collapse .main-menu{ 
    padding-top:5vw;
  }

  .footer-bottom-info h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
  }
  
  .navbar .navbar-collapse a {
    color: #1C2630;
    padding: 4.133vw 2.467vw;
    }

  .navbar-nav .special-btn .donate-btn {
    margin-bottom: 20px;
  }

  .lake-program-inner {
    padding: 15px;
  }

  .lake-program-section h3 {
    font-weight: 600;
    font-size: 3.733vw;
    line-height: 5.333vw;
    padding-bottom: 2.667vw;
    margin: 0px;
  }

  .lake-program-section .desc-content p {
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 5.867vw;
    color: #212121;
  }

  .lake-program-section .desc-content .lake-program-date {
    display: inline;
    font-size: 3.200vw;
    line-height: 5.867vw;
    
  }

  .lake-program-section .desc-content a {
    font-size: 3.200vw;
    line-height: 5.867vw;
    text-align: right;
    float: right;
  }
  .our-archivement {
    padding: 38px 0;
  }

  .lake-program-section .container>.row {
    margin: 0;
  }

  .lake-program-section .title-content {
    border: 0;
  }

  .our-archivement .container>.row {
    width: auto;
  }

  .our-achive-list {
    width: 100%;
    margin-bottom: 5.333vw;
  }

  .our-achive-inner-desc {
    padding: 5.867vw 0;
  }

  .top-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
  }

  .our-wel-imges {
    order: 1;
    width: 100%;
  }

  .our-mo-mon-desc {
    order: 2;
    width: 100%;
    margin-top: 10.667vw;
  }

  .welcom-mo-mon .title-text {
    font-size: 4.800vw;
    line-height: 7.200vw;
  }

  .our-wel-imges::after {
    width: 61%;
    border-radius: 16px;
    z-index: -1;
    height: 74.8vw;
    top: 12.800vw;
    right: -3%;
  }
}




@media screen and (max-width:768px) {
  .banner-text h3 {
    font-size: 15px;
    line-height: 24px;
  }

  .banner-text p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .Home-banners {
    /* margin-top: -48rem; */
    left: 15px !important;
  }

  .hide-mobile {
    display: none;
  }

  .carousel-item {
    /* background: #475443; */
  }

  .mobile-bg {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 17.39%, rgba(0, 0, 0, 0) 82.31%), url(../img/home-banners.png);
  background-size: cover;
  }

  .banner-card {
    margin-top: 0;
    margin-bottom: 10vw;
  }

  .carousel-indicators {
    left: 35px;
    right: auto;
    margin: 0;
    /* margin: 0 70%; */
    width: 91px;
    bottom: 18px;
  }
  .about-quotes-desc p{font-size: 27px;     line-height: 40px;}
}

@media screen and (min-width: 768px) {

  .restoration-desc p {
    font-size: 1.161vw;
    line-height: 2.322vw;
    margin-bottom: 1.089vw;
  }

  .restoration-text {
    font-size: 2.322vw;
    line-height: 3.483vw;
    margin: 0 0 2.451vw;
  }

  .mail-us {
    font-size: 13px;
  }

  .footer-bottom-info ul li a {
    font-size: 13px;
  }

  .social {
    margin-right: 18px;
  }
  .work-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  /* .work-section li {
    flex-basis: 25%;
    justify-content: space-between;
    flex-grow: 0;
    list-style-type: none;
  } */

  .work-section img {
    border-radius: 16px;
    max-width: 100%;
  }

}

@media screen and (min-width: 1280px) {
  
  .mail-us {
    font-size: 16px;
  }
  .footer-bottom-info ul li a {
    font-size: 14px;
  }

  .restoration-desc p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .restoration-text {
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 30px;
    font-weight: 300;
  }
  .restoration-text  strong{
    font-weight: 600;
  }

  .restoration-imges {
    display: grid;
    max-width: 100%;
  }

  .restor-works p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 50px;
  }
  .image-cards-section {
    display: flex;
  }

  .image-cards-section .image-cards-box {
    width: auto;
    margin-right: 32px;
    margin-bottom: 76.997px;
  }

  .image-cards-section .image-cards-box img {
    width: 286px;
    height: 394px;
  }

  .live-grow-section .row::before {
    content: "";
    background: url(../img/works/letus.png);
  }
  .live-grow-section img {
    width: 9.792vw;
    height: 7.917vw;
}
}

@media screen and (min-width:897px) and (max-width:1180px){
  .banner-card {
    margin-top: 0;
    margin-bottom: 12vw;
}
.w-100 {
  height:70vh;
}
.producing-img .living-text{
  font-size: 30px;
  line-height: 41px;
}

}
@media screen and (min-width:900px) and (max-width:915px){
  .w-100 {
    height: 100vh
}
.banner-card {
  margin-bottom: 51%!important;
}
.carousel-indicators {
  left: calc(50% - 360px);
}
}

@media screen and (min-width:844px) and (max-width:896px){
  .w-100 {
    height:100vh;
  }
  .banner-card {
    margin-bottom: 59%!important;
}
.left-title-content .restoration-text {
  font-size: 25px;
  line-height: 40px;
}
.producing-img .living-text{
  font-size: 34px;
  line-height: 40px;
}
.restoration{ padding: 50px 0 50px 0;}

}
@media screen and (min-width:820px) and (max-width:843px){
  .banner-card {
    margin-top: 0;
    margin-bottom: 17vw;
}
.w-100 {
  height:40vh;
}
.carousel-indicators {
  left: calc(50% - 379px);
}
  .dropdown-menu{
    transform:translate(0px, 0px);
  }
  .our-wel-imges::after {
    height: 37.019vw;
  }
  .maga-main-menu{
    padding: 16px 9px;
  }
  .out-line-widget-img-part.left-line::after {
    top: 7vw!important;
}
.restoration{ padding: 50px 0 50px 0;}

}
@media screen and (min-width:667px) and (max-width:670px){
  .our-achive-inner-desc p{
    font-size: 2.111vw;
    line-height: 2.667vw;
    margin-top: 0.5vw;
  }
  .our-wel-imges::after {
    height: 59.8vw;
    top: 8.8vw;
  }
  .restoration-imges::after {
    height: 51.44vw;
    top: 23.73vw;
    left: -2%;
    z-index: -1;
    width: 76%;
  }
  .our-mo-mon-desc p{
    font-size: 2.2vw;
    line-height: 3.2vw;
  }
  .restoration-desc p{
    font-size: 2.2vw;
    line-height: 3.2vw;
  }
  .restor-works p{
    font-size: 2.2vw;
    line-height: 3.2vw;
  }
  .work-section p{
    font-size: 1.3vw;
  }
  .work-section h4{
    font-size: 1.5vw;
  }
  .supporter-desc p{
    font-size: 1.5vw;
  }
  .banner-card {
    margin-top:0;
    margin-bottom: 13vw;
  }
  .Home-banners{
    left: 8.13vw;
  }
  .carousel-indicators{
    left: 72px;
    bottom: -2px;
  }
  .carousel-indicators button::before {
    font-size: 10px;
    line-height: 12px;
    top: -19px;
  }
  .banner-text p{
    font-size: 1.4vw;
    line-height: 2.5vw;
  }
  .restoration-desc.col-md-6 {
    margin-top: 6vw;
}
.producing-img{padding-left:0;}
.w-100 {
  height: 109vh;
}
.works{
  padding: 15px 0 100px 0;
}
.work-section-inner {
  margin-top: 35px!important;
}
.organic-group .restoration-imges::after {
  top: 36.73vw;
}
.producing-img .living-text{
  font-size: 36px;
  line-height: 45px;
}
.our-achive-inner-desc {
  max-height: 16.528vw;
}
.lake-program-section h3{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.lake-program-section .desc-content p{
  font-size: 2.2vw;
line-height: 3.2vw;
}
.lake-program-section .desc-content .lake-program-date{
  font-size: 1.8vw;
  line-height: 1.8vw;
}
.lake-program-section .desc-content a{
  font-size: 1.8vw;
  line-height: 1.8vw;
}
.work-section-inner a{
  margin-bottom: 0!important;
}
.our-mission-part .out-line-widget-desc{
  padding-bottom:0px;
  margin-top: 2.25vw;
}
.meet-sec .modal-center .body-card-imges{
  margin:0 auto 25px;
}

.lg-toolbar .lg-counter{
  bottom: -104vh;
}
.lg-counter, .lg-counter-all, .lg-counter .lg-counter-current {
  font-size: 11px;
  line-height: 21px;
}
.contact-form-title{
  font-size: 26px;
  margin-top: 50px;
  line-height: 34px;
}
.contactpage h3 {
  font-size: 26px;
}
.about-inner-desc h4{
  font-size: 14px;
}
.supporter-top-title h3 {
  font-weight: 300;
  font-size: 27px;
  line-height: 50px;
}
.welcom-mo-mon .title-text{
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 25px;
  }
  .restoration-text{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .about-inner-desc h3{
    font-size: 27px;
    line-height: 50px;
  }
  .about-inner-desc h4{
    font-size: 27px;
    line-height: 50px;
  }
  .about-inner-desc p {
    font-size: 1rem;
    line-height: 32px;
  }
  .about-inner-desc .more-policies{
    font-size: 1rem;
    line-height: 32px;
  }
  .about-quotes-desc{
    max-width: 100%;
  }
  .about-quotes-desc p{
    font-size: 27px;
    line-height: 40px;
  }
  .about-quotes {
    padding-left: 0;
    margin-top: 22px;
  }
  .our-values-title h3{
    font-size: 27px;
    line-height: 50px;
  }
  .our-values-inner-items h5{
    font-size: 12px;
    margin-bottom: 11px;
  }
  .our-values-inner-items p{
    font-size: 11px;
    line-height: 13px;
  }
  .our-values-full{
    height: 44.38vw;
  }
  .about-inner-desc h3{
    font-size: 27px;
    line-height: 50px
  }
  .out-line-widget-desc p{
    font-size: 1rem;
    line-height: 32px;
  }
  .out-line-widget-desc h4{
    font-size: 1rem;
    line-height: 32px;
  }
  .out-line-widget-title h3{
    font-size: 27px;
    line-height: 50px;
  }
  .our-mission-part .out-line-widget-desc h3{
    font-size: 27px;
    line-height: 50px;
  }
  .supporter-desc p{
    font-size: 11px;
    line-height: 16px;
  }
  .out-line-widget-img-part.left-line::after {
    top: 129px;
  }
  .react-multi-carousel-item .slideimage{
    padding: 0;
  }
  .Loction-info {
    margin-bottom: 1em;
  }
  .locations-sec{
    padding-bottom: 4em;
  }

  .left-image-part-philosophy::after {
    height: 67.556vw!important;
    bottom: 1.736vw!important;
    left: -21.833vw!important;
    width: 100%!important;
  }


  
/**667 end**/

}
@media screen and (max-width: 991px) {
  .btn-aligns {
    height: 30px;
    width: 100px;
    font-size: 13px;
  }
  .gallery-imgs-sec .click-loadmore {
    height: 166px;
    width: 166px;
  }
  .left-image-part-philosophy{
    position: relative;
  }
  .restoration-text{
    font-weight: 300;
  }
  .restoration-text strong {
    font-weight: 600;
  }
  }

@media screen and (max-width:639px) {
  .carousel-item::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgb(71 84 67) 0%, rgba(0, 0, 0, 0.11) 100%, rgb(71 84 67) 100%);
    }
  .hide-for-mobile-only {
    display: none;
  }
  .shwo-for-mobile-only {
    display: block;
  }
  .restoration-desc.col-md-6 {
    margin-top: 16vw;
}
  /*subscription*/
  
  .lets-us-work{
    height: 98.13vw;
  }
  .left-tleft-info .tracktor-icon{ display: none}
  .lets-us-work::before{
    height: 14.13vw;
    top: -13px;
  }
  .lets-us-work::after{
    width: 100%;
    height: 3px;
  }
  .lets-us-work .row{
    min-height:auto;
    padding: 4vw 0 0;
  }
  .live_project-info{
    padding-top: 12vw;
  }
  .live_project-info h3{
    font-size: 6.4vw;
    line-height:8.53vw;
  }
  .live_project-info p{
    font-size: 3.73vw;
    line-height: 8.53vw;
  }
  .newsletter-subscription-info{
    display: block;
    width: 100%;
  }
  .subscription-inner .text-input{
    line-height: 8.53vw;
    width: 100%;
    height:11.73vw;
    padding: 1.6vw 5.33vw 1.6vw 3.73vw;
    font-size: 3.73vw;
    margin: 6.67vw 0;
  }
  .btn-newsletter{
    width:100%;
    height: 11.73vw;
    font-size:3.73vw;
    line-height:4.27vw;
    margin-left: 0;
    background-color:#9a844f;
  }
 
.mobile-only-show-tracktor{
  display: block;
  text-align: center;
  position: absolute;
  bottom: 0;
}
.mobile-only-show-tracktor img{ max-width: 100%; width: 90px;}


  .subscription-inner{
    justify-content: center;
    align-items: self-start;
  }
  .our-supporter{
    padding:13.33vw 0vw;
  }
  .supporter-top-title h3{
    font-weight: 300;
    font-size: 27px;
    line-height: 50px;
  }
  .supporter-top-title h3 strong{
    font-weight: 600;
  }
  .supporter-itme{
    margin-top: 14.13vw;
   }
   .supporter-list{
    margin-bottom:10.133vw;
   }
   .supporter-list-inner{
    padding: 13.33vw 8vw;
   }
   .supporter-desc{
    margin-top: 12.8vw;
   }
   .supporter-desc p{
    font-size: 4.27vw;
    line-height: 8.53vw;
   }

   .work-section li img{
    width: 100%;
   }
   .work-section h4 {
    font-weight: 600;
    margin-top: 6.67vw;
    font-size: 4.27vw;
    line-height: 4.27vw;
  }
  .work-section p {
    font-size: 3.73vw;
    line-height: 6.4vw;
    max-width: 100%;
    width: 100%;
  }
  .restoration-text {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 4.800vw;
    line-height: 8vw;
  }
  .restoration-text strong{
    font-weight: 600;
    
  }

  .works{
    padding: 1.042vw 0 6.944vw 0;
  }

  .restoration-imges{
    padding-bottom: 30px;
  }
  .restoration-imges::after {
    width: 89%;
    height: 64vw;
    left: -5.07vw;
    top: 54.4vw;
  }

  .our-achive-inner-desc{
    height: 36.53vw;
  }
  .our-achive-inner-desc p{
    font-size: 4.27vw;
    line-height: 6.4vw;
    
  }
  .Home-banners{
      position: absolute;
      top: 5.87vw;
      left: 1.6vw !important;
   }
   /* .carousel-item img{
    min-height: 78.13vw;
    max-height: 75.73vw;
   } */

   .carousel-item img.hide-for-mobile-only{ display: none!important;}
   .banner-text p {
    font-size: 3.2vw;
    line-height: 5.53vw;
    margin: 0;
   }
   .carousel-indicators {
    right:0.8vw;
    left: auto;
   }

  .btn-aligns {
    width: 100px;
    font-size: 12px;
    line-height: 10px;
    margin-top: 20px;
  }
.banner-card {
  margin-bottom: 0;
  margin-top: 11.200vw;
}
.cap1 {
  display: block;
  height: auto;
}

.work-section-inner{
  width: 100%;
}
.left-title-content .restoration-text{
  font-size: 7.47vw;
  line-height: 10.67vw;
}

.about_inner{
  margin: 17.33vw 0 17.33vw;
}
.about-inner-desc h3{
  font-size: 27px;
  line-height: 50px;
  margin: 0 0 5.33vw;
}
.about-inner-desc h4{
  font-size: 27px;
  line-height: 50px;
}
.about-inner-desc p{
  font-size: 4.27vw;
  line-height: 8.53vw;
  margin-bottom: 5.33vw;
}
.about-inner-desc .more-policies{
  font-size: 3.47vw;
  line-height: 5.6vw;
}

.about-quotes{
  margin-top:10.2vw;
  justify-content: start;
}
.about-quotes span img{
  margin-bottom: 4vw;
}
.about-quotes-desc p{
  /* font-size: 6vw;
  line-height: 10.2vw; */
  font-size: 27px;
  line-height: 40px;
  
}
.our-values-title h3{
  font-size: 27px;
  line-height: 50px;
}
.more-btn {
 
  width: 100%;
  text-align: center;
}
.work-section-inner a{
  margin-bottom: 5em!important;
}

.contact-form-title {
  font-size: 6.67vw;
  line-height: 9.33vw;
}
.our-map-data{
  margin-top:6.6vw;
  margin-bottom: 0;
}
.our-values-full{
 padding: 15.2vw 0 17.6vw;
 
}
.our-values-title{ 
  margin: 0 0 10.67vw;
}
.our-values-title h3{
  font-size: 7.47vw;
  line-height: 12.8vw;
}
.our-values-inner-items h5{
  font-size: 4vw;
  line-height: 8.53vw;
  margin: 0 0 1.33vw;
}
.our-values-inner-items p{
  font-size: 3.47vw;
  line-height: 5.6vw;
}
.our-values-list{ margin-bottom: 10.67vw;}
.our-values-list:last-child{ margin-bottom: 0;}
.out-line-section-part_inner{
  padding:13.33vw 0;
}
.out-line-widget-title{
  margin-bottom: 4vw;
}
.out-line-widget-desc p{
  margin:  0 0 4vw;
  font-size: 4.27vw;
  line-height: 8.53vw;
}

.out-line-widget-desc h4{
  margin: 0 0 4vw;
  font-size: 4.53vw;
  line-height: 8vw;
}
.out-line-desc-img-part{
  order: 1;
}
.out-line-desc-part{
  order: 2;
}
.out-line-widget-title h3{
  /* font-size: 6.47vw;
line-height: 12.8vw; */
font-size: 27px;
line-height: 50px;
}
.out-line-widget-img-part{
  margin-top: 8vw;
  margin-bottom: 13.33vw;
}

.out-line-widget-img-part::after {
  width: 77.87vw;
  z-index: 0;
  height: 76.8vw;
  top: 0;
  right: -2.93vw;
}

.out-line-widget-img-part.left-line::after {
  left: -4.53vw;
  right: 0;
}
.our-mission-part .out-line-widget-desc h3{
  font-size: 27px;
  line-height: 50px;
}
.our-mission-part .out-line-widget-desc p{
  font-size: 16px;
  line-height: 24px;
}
.our-mo-mon-desc p{
  font-weight: 400;
  font-size: 4.267vw;
  line-height: 7.467vw;
}
.restoration-desc p{
  font-weight: 400;
  font-size: 4.267vw;
  line-height: 7.467vw;
}

.subscription-inner-email-fun .invalid-feedback{
  position: absolute;
  top: 65px;
  left: auto;
  bottom: auto;
}
.restor-works p{
font-weight: 400;
font-size: 4.27vw;
line-height: 8.53vw;
}

.our-people-info-top .modal-center {
  margin-bottom: 12px;
}

.gallery-grid-items .lg-react-element {
  justify-content: center;
  align-content: center;
}
.gallery-list{ width: 100%;}
.lg-counter, .lg-counter-all,
.lg-counter .lg-counter-current{
  font-size: 4.267vw;
  line-height: 13.333vw;
}
.principles-groups .title-text{
  font-size: 27px;
}
.Loction-info {
  margin-bottom: 1em;
}
.locations-sec{padding-bottom: 3em;}
.left-image-part-philosophy{
  position: relative;
  margin:15.69vw 0 6.69vw ;
}
.left-image-part-philosophy::after{
  height: 103.556vw;
  bottom: 4.736vw;
  left: -9.167vw;
  width: 100%;
}
.our-gallery-items .gallery-list-items{
  height: 256px;
}

  /*media end 639*/
  }

  @media screen and (max-width: 600px) {
  .navbar .navbar-toggler {
      position: relative;
      left: 83%;
  }
}
@media screen and (orientation:landscape)and (min-device-width: 700px) and (max-device-width: 991px) {
   .banner-card {
    margin-bottom: 20%;
    
} 

@media screen and (orientation:landscape)and (min-device-width: 540px) and (max-device-width: 991px) {
  .footer-bottom-info h4 {
    font-size: 1.5vw;
}
}
/* .Home-banners {
  position: absolute;
  bottom: auto;
  top: 3.72vw;
  left: 11.8vw !important;
} */
.banner-text p {
  font-size: 10px;
  line-height: 16px;
}
.btn-aligns {
  height: 30px;
  width: 100px;
  font-size: 13px;
}
.restor-works p {
  font-size: 1.161vw;
  line-height: 2.322vw;
  margin-bottom: 3.089vw;
}
}
@media screen and (min-width:700px) and (max-width:740px){
  .w-100 {
    height: 107vh
}
.banner-card {
  margin-bottom: 18%;
}
.carousel-indicators{
  left: calc(50% - 261px);
  bottom: -3px;
}
.carousel-indicators button::before {
  font-size: 11px;
  line-height: 28px;
}
.btn-aligns {
  height: 30px;
  width: 100px;
  font-size: 11px;
}
}
/* @media screen and (min-width: 1200px) {
.Home-banners {
  position: absolute;
  bottom: auto;
  top: 6.72vw;
}
}

@media(min-device-width: 1220px) 
and (max-device-width:1420px) {


} */



/* /* naveen media query * */
@media only screen and (max-width: 767px) {
  .contactpage h3{
    line-height: 32px;
    }
/*   
  .mainbannertop h1 {
    padding-left: 2.5rem;
} */
  .col1 {
    padding: 1em;
}
  .info-sec1 .col1 h3{
    line-height: 35px;
  }
  .contactpage {
    line-height: 32px;
  }
  .contactpage p{
  font-size: 16px;
  }
  .contactpage h3{
    font-size: 26px;
    }
  .infobox {
    position: static;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 30px;
  }
  .info-sec1 {
    max-height: 988px;
}
.info-sec1 .col1 h3{
  width: 100%;
  max-width: 100%;
  font-size: 27px;
  margin-bottom: 23px;
  margin-top: 40px;
}
.our-map-title{
  font-size: 27px;
}
.info-sec1 .col1 p{
  width: 100%;
}
.contactfrom h3{
  width: 62%;
  margin-top: 20px;
  padding: 0 !important;
}
.info-sec1-row{
  justify-content: center;
}
.contactfrom {
  padding: 10px 0 0 ;
}

.our-philosophy-banner .restoration-text {
  font-size: 27px;
  font-weight: 600;
  line-height: 48px;
  color: rgba(255, 255, 255, 1);
  margin: 0;
}
.our-philosophy-banner, .mainbannertop{
  height: 230px;
  min-height: 230px;
}
.image-ph {
  margin-bottom: 3em;
}
.principle-content br {
  display: none;
}
.our-gallery-items .gallery-list-iems{
  width: 50%;
  margin-bottom: 16px;
  padding: 0px;
}
.loadmore-data .btn-more-list{
  padding: 0 8px;
}
.loadmore-data{ width: 50%;}
.locations-sec{
  padding-top:0px;
}
.locations-sec  .Loction-info h3{ margin-bottom: 0;}
.navbar-collapse{
  /* max-height:100vh; */
  /* height: calc(100vh - 54px);
  overflow: hidden;
  overflow-y: scroll; */
}
.maga-main-menu .text-left:last-child{ margin-bottom: 0; }
}

@media(min-width: 768px) and (max-width: 1024px) {
  .contact-form-title {
    font-size: 3.222vw;
    line-height: 4.472vw;
    width: 72%;
  }
  .supporter-top-title h3 {
    font-weight: 300;
    font-size: 27px;
    line-height: 50px;
  }
  .principles-groups .title-text{
    font-size: 27px;
  }
  .about-inner-desc .more-policies {
    font-size: 1rem;
    line-height: 32px;
  }
  .about-inner-desc p {
    font-size: 1rem;
    line-height: 32px;
  }
  .about-inner-desc h4 {
    font-size: 27px;
    line-height: 50px;
  }
  .our-values-title h3{
    font-size: 27px;
    line-height: 50px;
  }
  .our-values-inner-items h5{
    font-size: 12px;
    margin-bottom: 11px;
  }
  .our-values-inner-items p{
    font-size: 11px;
    line-height: 13px;
  }
  .our-values-full{
    height: 35.38vw;
  }
  .about-inner-desc h3{
    font-size: 27px;
    line-height: 50px
  }
  .works{ padding-top: 0;}
  .out-line-widget-desc p{
    font-size: 1rem;
    line-height: 32px;
  }
  .out-line-widget-desc h4{
    font-size: 1rem;
    line-height: 32px;
  }
  .out-line-widget-title h3{
    font-size: 27px;
    line-height: 50px;
  }
  .our-mission-part .out-line-widget-desc h3{
    font-size: 27px;
    line-height: 50px;
  }
  .supporter-desc p{
    font-size: 13px;
    line-height: 20px;
  }
  .out-line-widget-img-part.left-line::after {
    top: 11vw;
  }
  .principles-groups .restoration-text{min-height: 40px;}
  .left-image-part-philosophy::after {
    height: 46.556vw;
    bottom: -2.264vw;
    left: -6.833vw;
    width: 100%;
  }
  
}
@media screen and (orientation:landscape)and (min-device-width: 768px) and (max-device-width: 1024px) {
  .our-philosophy-banner {
    max-height: 250px;
  }
.our-achive-inner-desc p {
  font-size: 1.024vw;
  line-height: 1.74vw;
}
.info-sec1 .col1 p{margin-top: 0 !important;}
.contactfrom h3{
  font-size: 27px;
  margin-top: 0;
  line-height: 50px;
}

.out-line-widget-img-part.left-line::after {
  top: 2vw;
}
.left-image-part-philosophy::after {    height: 43.556vw;
  left: -59.833vw;
  bottom: 1.736vw}
}
@media(min-width: 844px) and (max-width:896px ) {
  .out-line-widget-img-part.left-line::after {
    top: 5vw;
}
.left-image-part-philosophy::after {
  height:45.556vw;
  left:-9.5vw;
  bottom: -2.264vw;
}
}
@media(min-width: 768px) and (max-width: 1400px) {
  .contactpage {
    line-height: 32px;
  }
  .contactpage p{
  font-size: 16px;
  }
  .contactpage h3{
    font-size: 25px;
    }  
  .contactfrom h3 {
    width: 74%;
  }
  .info-sec1 {
    /* max-height: 94vw; */
  }
.info-sec1 .col1 p {
  /* width: 39vw; */
}
.info-sec1 .col1 h3 {
  width: 39vw;
}
.infobox {
  right: 5%;
  left: unset;
}

}

@media screen and (max-width:768px) {
  .our-archivement {
    padding: 30px 0;
}
.restoration{
  padding: 50px 0;
}

.work-section-inner {
  margin-top: 10px;
}
.work-section-inner a {
  margin-bottom: 1.5em;
}
.modal .modal-content {
  height: 800px;
  width: 100%;
}
.meet-sec h2 {
  font-size: 27px;
  margin-bottom: 29px;
}
.meet-sec{
  padding-top: 50px;
}
.principles-groups{
  padding-top: 22px;
}
.meet-sec .our-people-list{
  margin-bottom: 30px;
}
.info-sec1 .col1 p{width: 90%;}
.works{ padding: 0px 0 100px 0;}
}
@media only screen and (max-width: 896px) and (orientation: landscape) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    transform: none;
    max-height: 238px;
    overflow: hidden;
    overflow-y: scroll;
    top:0
  }
  .navbar .navbar-collapse a{
    padding: 2.133vw 2.467vw;
  }
  .maga-main-menu{
    padding: 14px 9px;
    margin-top: 0;
  }
  .navbar-collapse .main-menu {
    padding-top: 1vw;
  }
  .scrolled .navbar-collapse .main-menu{ 
    padding-top:5vw;
  }

  .navbar-collapse{
    max-height: calc(100vh - 54px);
    overflow: hidden;
    overflow-y: scroll;
  }
  
}
@media only screen and (max-width: 844px) and (orientation: landscape) {
  .lake-program-section h3 { font-size: 18px;   line-height: 27px;  padding-bottom: 1vw; }
  .lake-program-section .desc-content p{font-size: 1.7vw; line-height: 3.867vw;}
  .lake-program-section .desc-content .lake-program-date{font-size: 1.65vw;}
  .lake-program-section .desc-content a {font-size: 1.65vw;}
  .welcom-mo-mon .title-text{    font-size: 18px;    line-height: 27px;  }
  .our-wel-imges::after {  height: 49.8vw;  }
  .restoration-imges::after {height: 49.019vw; top: 26.007vw;}
  .our-mo-mon-desc p{font-size: 1.7vw;}
  .our-achive-inner-desc {  max-height: 12.528vw;}
  .our-achive-inner-desc p{
    /* font-size: 1.7vw;    line-height: 3.22vw; */
    font-size: 1.60vw;
    line-height: 1.9vw;
  }
  .restoration-desc p{font-size: 1.7vw; line-height: 3.22vw;}
  .restor-works p{ font-size: 1.7vw; line-height: 3.22vw;}
  .work-section p{font-size: 1.11vw; line-height: 1.57vw;}
  .live_project-info h3{font-size: 1.9vw;}
  .live_project-info p{font-size: 1vw;}
  .btn-newsletter{margin-left: -5.04vw;}
  .restoration-imges{padding-bottom:30px;}
  .carousel-inner .w-100{
    height: 100vh;
  }
  .mobile-bg {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 17.39%, rgba(0, 0, 0, 0) 82.31%), url(../img/home-banners.png);
    background-size: cover;
  }
  .Home-banners .d-none.d-sm-block{ display: none!important;}
  .banner-content-items{ width: 100%;}
  .carousel-item::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgb(71 84 67) 0%, rgba(0, 0, 0, 0.11) 100%, rgb(71 84 67) 100%);
}
  .carousel-item img.show-for-mobile-only{ display: block!important;}
  .Home-banners {
    position: absolute;
    top: 1vw;
    /* left: 13.6vw !important; */
    left: 3.6vw !important;
  }
  .carousel-indicators { right: 0.8vw;  left: auto; }
  .banner-text p{ margin: 1.5vw 0;  }
  .left-image-part-philosophy{ position: relative;}
  .left-image-part-philosophy::after {
    height: 47.556vw;
    left: -5.5vw;
    bottom: -2.264vw;
    width: 100%;
  }
  .maga-main-menu .text-left:last-child{
    margin-bottom: 0;
  }
 
  .scrolled .header-logo {
    width: 30%;
  }
  .works{ padding: 0px 0 80px 0;}
  .restoration-text{margin-bottom: 2rem;}
}

@media screen and (min-width:991px) and (max-width:2400px){
  .meet-sec .bio-top {
    margin-top: 45px;
  }
}

@media screen and (min-width:844px) and (max-width:849px){
  .banner-content-items {  width: 80%; }
  .banner-text p { font-size: 12px; line-height: 23px; }
  .banner-card{margin-bottom: 20% !important;}
  .lake-program-section h3 { font-size: 16px; line-height: 23px;}
  .lake-program-section .lake-program-date {  font-size: 1.5vw; }
  .lake-program-inner {  min-height: 18.03vw; }
  .carousel-indicators {  right: 4.5vw; }
  .works{ padding: 0px 0 100px 0;}
}



@media screen and (min-width:667px) and (max-width:670px){
  .scrolled .header-logo {
    width: 15%;
    
}
.works{ padding: 0px 0 100px 0;}
.restoration-text{margin-bottom: 2rem;}
}
@media only screen and (max-width: 736px) and (orientation: landscape) {
  .scrolled .header-logo {
    width: 15%;
}
.restoration-text{margin-bottom: 2rem;}
}

@media(min-width: 992px) and (max-width: 1440px) {
  .lake-program-section h3 {
    font-size: 1.016vw;
    margin-bottom: 0.726vw;
  }
  .maga-main-menu{
    width: 38.556vw;
  }
  .sub-menus .text-left a{
    font-size: 0.82vw;
    line-height: 1.161vw;
  }
  .dropdown-menu[data-bs-popper]{
    left: -63px;
  }
  .dropdown-header{
    font-size: 0.972vw;
    line-height: 1.111vw;
  }
  .sub-menus .text-left a{
    padding:0.625vw 0.694vw!important;
  }
  .subnav{
    width: 100%;
  }

  .ch-heading.dropdown-header a{
    padding-left: 0 !important;
  }
  
}
@media (max-width:542px) {
  .contactfrom h3{
    width: 100%;
  }
}