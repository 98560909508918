.forgotpassword-sec .contact-form-title{text-align: center; margin-top: 5vw;}

.password-form{background-color:#E6E9DD;padding-left: 3vw;margin-top: 3vw;}
.forgotpassword-sec .sign-btn{
    background-color: #F0B95E;
    
    border: none;
    color: black;
    font-weight: 600;
    padding: 18px 22px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 16px;
    margin-top: 12px;
}
.reset-btn{
    background-color: #E6E9DD;
    border:1px solid black;
    color: black;
    font-weight: 600;
    padding: 16px 18px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 16px;
    margin-top: 12px;
    margin-bottom: 30px;
}
@media (max-width:639px){
    .forgotpassword-sec .contact-form-title{ margin-top: 16vw;}
    .form-control{height:10vw}
    .sign-btn{padding: 14px 8px;}
    .reset-btn{padding: 14px 8px;}
}
@media (min-width:640px) and (max-width:1200px){
    .forgotpassword-sec .contact-form-title{ margin-top: 10vw;}
    .form-control{height:5vw}
    .reset-btn{padding: 16px 16px;}
}
.message.success-message {
    text-align: center;
    color: green;
}