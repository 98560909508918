/* .donate-banner {
    background: url("../img/donate-banner.png") no-repeat;
    background-position: center;
    background-size: cover;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */
.donate-project-split-line hr{
    background: silver;
    height: 2px;
    border: none;
    margin-bottom: 80px;
    margin-top: 20px;
}
.donate-project-sec .form-label {
    margin-bottom: 0.5rem;
    color: #666666;
    font-weight: 500;
    font-size: 14px;
   line-height: 32px;
}
.contribute-sec.row {
    margin-top: 25px;
}

/*contribution style*/
.contribute-sec p{
    margin-bottom: 13px;
    color: #666666;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
}


.list-of-contribution{ position: relative; float: left;}

.contribution-info{ display: block; position: relative; margin-bottom: 0;}
.contribution-info li{ list-style: none; margin-right: 15px; padding: 0; display: inline-block;}


.cont-list{ padding: 0; margin: 0;}
.cont-list input[type="radio"]:not(:checked), 
.cont-list  input[type="radio"]:checked {
  position: absolute;
  left: -9999%;
}
.cont-list input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #5BB94D;
  color: #000;
  font-family: Poppins;
  padding: 8px 21px;
  border-radius: 54px;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
}
.cont-list input[type="radio"]:checked + label {
  border: 1px solid #5BB94D;
  color: #FFFFFF;
  background-color: #5BB94D;
}
.contribution-text-file{ margin: 0; float: left;}
.contribution-text-file .contribution-text {
    border: 1px solid #5BB94D;
    color: #000;
    border-radius: 54px;
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    max-width: 177px;
    padding: 8px 21px;
    width: 100%;
}     

/**/

.recurring-selection{
    position: relative;
}
.recurring-cont{
    clear: both;
    display: block;
    position: relative;
    margin: 15px 0 0;
}
.recurring-check-box .form-check-input{
    border-radius: 4px;
    border: 1px solid #ACB9C6;
    background-color:#FFF;
    width: 20px;
    height: 20px;
    box-shadow: none;
    vertical-align: middle;
}
.recurring-check-box .form-check-input[type=checkbox]:checked {
    accent-color: #5BB94D !important;
    background-color:#5BB94D;
    box-shadow: none;
    border: 1px solid #5BB94D;
}

.recurring-check-box .form-check-label{
    color: #212121;
    font-size: 12px;
    font-family: Poppins;
    line-height: 27px;
    margin-left: 10px;
}
.read-only-input {
  color: #999; /* Change this color to your desired color */
  background-color: #f2f2f2; /* Change this background color to your desired color */
}
.read-only-input:focus {
  color: #999; /* Change this color to your desired color */
  background-color: #f2f2f2; /* Change this background color to your desired color */
}
.recurring-list{
  margin: 7px 0;
  display: none;
}
.form-sec-info {
  padding: 0 5px;
}

.form-sec-info .form-label{
    color:#666;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 32px;
}
 .form-sec-info .form-control {
    border: 1px solid #ACB9C6;
    border-radius: 4px;
    height: 44px;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }
  .form-sec-info .text-select{
    -webkit-appearance: none;
    -moz-appearance: window;
    padding: 0 13px;
    background-image: url("../img/select-arrow.png");
    background-repeat: no-repeat;
    background-position: 99% center ;
    background-size: auto;
    font-family: 'Poppins';
  }




.contribute-sec input.payment-value {
    background: transparent;
    width: 3vw;
    color: white;
    border: none;
}
.contribute-sec input.payment-option {
    background: transparent;
    width: 7vw;
    border: none;
}
/* input.payment-value:hover {
    text-decoration: underline;
    text-decoration-color: #ffc107;
    
} */
input.payment-value:focus {
    text-decoration-color: #5BB94D !important;
    text-decoration: underline;
    border:none;
  }
  input:focus-visible {
    /* border: none !important; */
    /* color: #fff; */
    outline: none;
}
   
.btn-contribution:hover{
    background-color: #5BB94D;
    color: #fff;
}
/* .donate-project-sec p{color: #666666;font-weight: 500;font-size: 14px;margin-bottom: 2vw;} */
.donate-project-sec .btn-contribution{display: inline;color: #000000;font-size: 14px;padding: 10px 20px 9px;border: 1px solid #5BB94D;border-radius: 54px;margin-right: 1vw}

input[type=checkbox]:checked {
    accent-color: #5BB94D !important;
}
.contribute-sec .form-sec{margin-right: 2em;}
.myCheck{margin-left:5px ;}
.contribute-sec .check-box{display: flex;}

.personal-section{
    position: relative;
    margin: 11px 0 0;
}

 h3.top-title-info{
    color:#212121;
    font-size: 24px;
    font-family: Poppins;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 25px;
    padding: 0;
}  
 h3.top-title-info strong{ font-weight: 600;}
  
.billing-section{
    margin: 20px 0 0;
    display: block;
    position: relative;
}


.donate-project-form .form-control {
    border: 1px solid #ACB9C6;
    border-radius: 4px;
    height: 44px;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
.donate-project-form .text-select{
    -webkit-appearance: none;
    -moz-appearance: window;
    padding: 0 13px;
    background-image: url("../img/select-arrow.png");
    background-repeat: no-repeat;
    background-position: 99% center ;
    background-size: auto;
    font-family: 'Poppins';
  }
  .personal-list-itmes  .form-control {
    border: 1px solid #ACB9C6;
    border-radius: 4px;
    height: 44px;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }
  .personal-list-itmes .text-select{
    -webkit-appearance: none;
    -moz-appearance: window;
    padding: 0 13px;
    background-image: url("../img/select-arrow.png");
    background-repeat: no-repeat;
    background-position: 99% center ;
    background-size: auto;
    font-family: 'Poppins';
  }

  
  .personal-list-itmes .form-label{
    color:#666;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
  }
  .personal-list-itmes .form-control{
    border-radius: 4px;
    border: 1px solid #ACB9C6;
    background-color: #FFF;
    font-family: Poppins;
    height: 44px;
    padding:6px 20px;
    font-size: 14px;
    line-height: 32px;
  }


  .payment-btn{
    background-color: #F0B95E;
    border: none;
    color: #212121;
    border-radius: 10px;
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;     
    padding: 14px 20px; 
    line-height: 16px;
    margin-top: 20px;
    
}
.payment-btn:active,
.payment-btn:focus,
.payment-btn:hover{
    background: #DE9D33!important;
    color: #212121!important;
}

  
@media(max-width:767px) {
  .contribute-sec .check-box{
    display: block;
  }
  .form-sec-info{
    width: 100%;
    margin: 0 0 10px;
  }

}
    
@media(max-width:639px) {
    .donate-project-sec hr{width: 360px;}
    .contribute-sec input.payment-value {width: 14vw;margin-top: 8vw;}
    .contribute-sec input.payment-option {width: 32vw;margin-top: 7vw;}
    
}   
@media(min-width:640px) and (max-width:1400px) {
    .donate-project-sec hr{width: 43em;}
    .contribute-sec input.payment-value {width: 7vw;margin-top: 2vw;}
    .contribute-sec input.payment-option {width: 17vw;margin-top: 4vw;}  
}  


@media(max-width:1024px) {
        .donate-left{
            width: 100%;
        }
}

@media (orientation: landscape){
    @media(min-width:640px) and (max-width:1200px){
        .donate-project-sec hr{width: 50em;}
    }
  }